import React from 'react'
import { Button, /* Checkbox, */ Form, Input, message } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { connect } from 'dva'
// import { router } from "dva";

import IntlMessages from '@src/util/IntlMessages'
import CircularProgress from '@src/components/CircularProgress/index'
import { StoreState } from '@src/interfaces'
import * as authServices from '@src/services/auth'
import logoBackground from '../assets/images/signin.png'
// import logo from '../assets/images/hyundailogo.png'
import { IS_DEBUG } from '@src/constants/constants'
const FormItem = Form.Item

class SignIn extends React.Component<
  {
    dispatch?: any
    showMessage?: any
    history?: any
    authUser?: any
    loader?: any
    alertMessage?: any
  },
  {
    tokenCapcha?: string
    captchaId?: string | number
    captcha: string
    accountKitToken?: string
  }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      captchaId: 0,
      captcha: '',
      accountKitToken: '',
    }
  }

  form = React.createRef<any>()

  handleSubmit = (values: Record<string, any>) => {
    const { dispatch } = this.props
    dispatch({
      type: 'auth/showAuthLoader',
    })
    dispatch({
      type: 'auth/userSignIn',
      payload: {
        ...values,
        captchaId: this.state.captchaId,
        tokenCapcha: this.state.tokenCapcha,
      },
    })
  }

  async loadCaptcha() {
    //load captcha
    const captInfo = await authServices.getCaptcha()
    if (IS_DEBUG) {
      console.log(
        `🚀 ~ file: SignIn.tsx ~ line 57 ~ loadCaptcha ~ captInfo`,
        captInfo
      )
    }

    this.setState({
      captchaId: captInfo.data.id,
      captcha: captInfo.data.data,
      tokenCapcha: captInfo.data.tokenCapcha,
    })
  }

  componentDidMount() {
    this.loadCaptcha()
  }

  componentDidUpdate() {
    const { dispatch } = this.props
    if (this.props.showMessage) {
      setTimeout(() => {
        dispatch({
          type: 'auth/hideMessage',
        })
      }, 100)
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/')
    }
  }

  render() {
    const { showMessage, loader, alertMessage } = this.props

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={logoBackground} alt="MediaOne" />
              </div>
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signIn" />
                </h1>
                {/* <p>
                  <IntlMessages id="app.userAuth.bySigning" />
                </p> */}
                <p>
                  <IntlMessages id="app.userAuth.getAccount" />
                </p>
              </div>
              <div className="gx-app-logo">
                {/* <img alt="example" src={logo} /> */}
                <span
                  style={{ margin: '0 auto', verticalAlign: 'center' }}
                >{` `}</span>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                ref={this.form}
                onFinish={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem
                  name="username"
                  initialValue=""
                  rules={[
                    { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                  ]}
                >
                  <Input placeholder="Tên đăng nhập" />
                </FormItem>
                <FormItem
                  name="password"
                  initialValue=""
                  rules={[
                    { required: true, message: 'Vui lòng nhập mật khẩu!' },
                  ]}
                >
                  <Input type="password" placeholder="Mật khẩu" />
                </FormItem>
                <FormItem>
                  <div
                    className="captcha"
                    dangerouslySetInnerHTML={{ __html: this.state.captcha }}
                  ></div>
                </FormItem>
                <FormItem name="captchaText">
                  <Input
                    addonAfter={
                      <SyncOutlined
                        onClick={() => {
                          this.loadCaptcha()
                        }}
                      />
                    }
                  />
                </FormItem>
                {/* <FormItem
                  name="remember"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Checkbox>
                    <IntlMessages id="appModule.iAccept" />
                  </Checkbox>
                  <span className="gx-signup-form-forgot gx-link">
                    <IntlMessages id="appModule.termAndCondition" />
                  </span>
                </FormItem> */}
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.login" />
                  </Button>
                  {/* <span>
                    <IntlMessages id="app.userAuth.or" />
                  </span>{' '}
                  <Link to="/signup">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Link> */}
                </FormItem>
                {/* <div className="gx-flex-row gx-justify-content-between">
                  <span>or connect with</span>
                  <ul className="gx-social-link">
                    <li>
                      <GoogleOutlined onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userGoogleSignIn();
                      }} />
                    </li>
                    <li>
                      <FacebookOutlined onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userFacebookSignIn();
                      }} />
                    </li>
                    <li>
                      <GithubOutlined onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userGithubSignIn();
                      }} />
                    </li>
                    <li>
                      <TwitterOutlined onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userTwitterSignIn();
                      }} />
                    </li>
                  </ul>
                </div>
                <span
                  className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
              </Form>
            </div>

            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {showMessage && alertMessage
              ? message.error(alertMessage.toString(), 10)
              : null}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }: StoreState) => {
  const { loader, alertMessage, showMessage, authUser } = auth
  return { loader, alertMessage, showMessage, authUser }
}

export default connect(mapStateToProps)(SignIn)
